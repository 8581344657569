import React, { useEffect, useRef, useState } from "react";
import cross from "../../assets/ovelCross.svg";
import creditCard from "../../assets/creditCard.svg";
import jioPay from "../../assets/jioPay.png";
import airtelPay from "../../assets/airtelPay.svg";
import mobi from "../../assets/mobi.svg";
import OlaMoney from "../../assets/Ola_Money.png";
import FreeCharge from "../../assets/freecharge_logo.svg";
import Amazon from "../../assets/amazon-2-logo-svgrepo-com.svg";
import PhonePe from "../../assets/PhonePe-Logo.wine.svg";
import paytm from "../../assets/paytmSvg.svg";
import { handlePayment } from "../Upi/upiUtility";
import Upi from "../Upi/Upi";
import NetBanking from "../NetBanking/NetBanking";
import pay_later from "../../assets/payLater.svg";
import PayLater from "../paylater/Paylater";
import Card from "../Card/Card";
import AmexCreditCard from "../Card/AmexCreditCard";
import Wallet from "../Wallet/Wallet";
import upiLogo from "../../assets/upi.svg";
import CardLessEMI from "../cardlessEmi/cardlessEmi";
import flexmoney from "../../assets/flexmoney.png";
import zestmoney from "../../assets/zestmoney.svg";
import { isMobile } from "react-device-detect";
import bottomLogo from "../../assets/bottomLogo.svg";
import schoolSvg from "../../assets/schoolSvg.svg";
import gPay from "../../assets/googlePay.png";

import phonepe from "../../assets/phonepe.png";
import { Link, useNavigate } from "react-router-dom";
import FeesBreakdown from "./FeesBreakdown";
import { initiateCard } from "../Card/easebuzz";
import { QRCodeSVG } from "qrcode.react";

import { LiaUniversitySolid } from "react-icons/lia";

function HomeScreen() {
  const [wallet, setWallet] = useState({
    walletImg: "",
    walletHeading: "",
    walletTxt: "",
    walletName: "",
  });

  const [currentItem, setCurrentItem] = useState("");
  function walletSet(
    walletImg: any,
    walletHeading: string,
    walletTxt: string,
    walletName: string
  ) {
    setWallet((pre) => {
      return {
        ...pre,
        walletImg,
        walletHeading,
        walletName,
        walletTxt,
      };
    });
  }
  let urlParams = new URLSearchParams(window.location.search);
  const paymentId: any = urlParams.get("session_id");
  const easebuzzPaymentId: any = urlParams.get("payment_id");
  const easebuzz: any = urlParams.get("easebuzz_pg");
  const isBlank: any = urlParams.get("is_blank");
  const schoolName: any = urlParams.get("school_name");

  const collect_request_id: any = urlParams.get("collect_request_id");
  const orignalAmount: any = parseFloat(urlParams.get("amount") || "0");
  const [amount, setAmount] = useState(orignalAmount);
  const [isEasebuzzSchool, setIsEasebuzzSchool] = useState<boolean>(false);

  const [easebuzzQr, setEasebuzzQr] = useState({
    qr_code: "",
    phonePe: "",
    googlePe: "",
    paytm: "",
  });
  let upiEnabled: any = urlParams.get("upi") === "false" ? false : true;
  let cardEnabled: any = urlParams.get("card") === "false" ? false : true;
  let cardLessEnabled: any =
    urlParams.get("cardless") === "false" ? false : true;
  let netBankingEnabled: any =
    urlParams.get("netbanking") === "false" ? false : true;
  let payLaterEnabled: any =
    urlParams.get("pay_later") === "false" ? false : true;
  let walletEnabled: any = urlParams.get("wallet") === "false" ? false : true;

  const platform_charges = urlParams.get("platform_charges");
  let platformCharges: any;
  if (platform_charges) {
    const decodedPlatformCharges = decodeURIComponent(platform_charges);
    platformCharges = JSON.parse(decodedPlatformCharges);
  } else {
    console.error("platform_charges parameter not found in the URL");
  }

  const [scrollPercentage, setScrollPercentage] = useState(0);
  const qrRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const totalScrollHeight =
        document.documentElement.scrollHeight - windowHeight;
      const scrollPosition = window.scrollY;

      const percentage = (scrollPosition / totalScrollHeight) * 100;
      setScrollPercentage(percentage);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showQrMenu, setShowQrMenu] = useState(
    !upiEnabled || isMobile || amount >= 100000 ? false : true
  );
  const [showUpiWindow, setShowUpiWindow] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const [provider, setProvider] = useState(upiEnabled ? "upi" : "");
  const [cardLessEmiProvider, setCardLessEmiProvider] = useState("");
  const [payMethod, setPayMethod] = useState("");
  const [upiProvider, setUpiProvider] = useState("");
  const [upiProviderId, setUpiProviderId] = useState("");

  const [cardLessEmiProviderImg, setCardLessEmiProviderImg] = useState<
    string | null
  >(null);
  const [disableUpi, setDisableUpi] = useState(false);
  const [cardLessEmiProviderText, setCardLessEmiProviderText] = useState("");
  const [erpLogo, setErpLogo] = useState("");
  const [cardLessEmiProviderHeading, setCardLessEmiProviderHeading] =
    useState("");
  const [breakDown, setBreakdown] = useState({
    baseRate: amount,
    total: amount,
    convenienceFee: 0,
    gst: 0,
  });

  useEffect(() => {
    if (amount > 100000 || !upiEnabled) setDisableUpi(true);
  }, [amount]);

  useEffect(() => {
    if (scrollPercentage >= 30) {
      setShowQrMenu(false);
    }
  }, [scrollPercentage]);

  useEffect(() => {
    //for qr

    handlePayment(qrRef.current, paymentId, collect_request_id);
    payRef.current.disabled = true;
  }, []);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_PAYMENT_BACKEND}/edviron-pg/erp-logo?collect_id=${collect_request_id}`
        );
        const data = await res.json();
        if (data.status === "success") {
          setErpLogo(data?.logo);
        }
      } catch (error) {}
    };
    const fetchScoolId = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_PAYMENT_BACKEND}/edviron-pg/school-id?collect_id=${collect_request_id}`
        );
        const easeBuzzQr = await fetch(
          `${process.env.REACT_APP_PAYMENT_BACKEND}/easebuzz/upiqr?collect_id=${collect_request_id}`
        );

        const data = [
          "66f4f11ddc103d41887f0924",
          "66f4f0eadc103d41887f0915",
          "66f4f146dc103d41887f0931",
          "66f4f16fdc103d41887f0940",
          "66f4f20adc103d41887f095a",
          "66f54d77db0907c76810fbd0",
          //"65d443168b8aa46fcb5af3e4",
        ].includes(await res.text());

        setEasebuzzQr(await easeBuzzQr.json());
        setIsEasebuzzSchool(data);
      } catch (error) {}
    };

    fetchScoolId();
    fetchLogo();
  }, [collect_request_id]);
  const payLaterRef = useRef<(() => void) | null>(null);
  const netBankingRef = useRef<(() => void) | null>(null);
  const cardRef = useRef<(() => void) | null>(null);
  const walletRef = useRef<((id: string) => string) | null>(null);
  const upiRef = useRef<
    ((id: string, collect_request_id: string) => string) | null
  >(null);
  const payRef = useRef<any>(null);
  const cardlessEmiRef = useRef<(() => void) | null>(null);
  const handlePayButton = () => {
    if (payLaterRef.current && payMethod === "payLater") {
      payLaterRef.current();
    } else if (netBankingRef.current && payMethod === "netBanking") {
      netBankingRef.current();
    } else if (cardlessEmiRef.current && payMethod === "cardlessEmi") {
      cardlessEmiRef.current();
    } else if (walletRef.current && payMethod === "wallet") {
      walletRef.current(wallet.walletName);
    } else if (upiRef.current && payMethod === "upi") {
      upiRef.current(paymentId, collect_request_id);
    } else if (cardRef.current && payMethod === "cards") {
      cardRef.current();
    } else {
      payRef.current.disabled = true;
    }
  };

  const scrollToTop = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  enum charge_type {
    FLAT = "FLAT",
    PERCENT = "PERCENT",
  }

  function finalAmountWithMDR(platform_type: String, payment_mode: String) {
    // console.log(payment_mode);

    try {
      if (orignalAmount < 0) return { error: "Amount cannot be negative" };

      let ranges: any = null;
      platformCharges.forEach((platformCharge: any) => {
        if (
          (!ranges &&
            platformCharge.platform_type.toLowerCase() ===
              platform_type.toLowerCase() &&
            platformCharge.payment_mode.toLowerCase() === "others") ||
          (platformCharge.platform_type.toLowerCase() ===
            platform_type.toLowerCase() &&
            platformCharge.payment_mode.toLowerCase() ===
              payment_mode.toLowerCase())
        ) {
          ranges = platformCharge.range_charge;
        }
      });

      if (!ranges) return { error: "MDR not found" };

      let platformCharge: any = null;
      ranges.forEach((range: any) => {
        if (!platformCharge && (!range.upto || range.upto >= amount)) {
          platformCharge = range;
        }
      });

      let finalAmount: number = 0;
      if (platformCharge.charge_type === charge_type.FLAT) {
        finalAmount = orignalAmount + platformCharge.charge * 1.18;
        let gst;
        gst = platformCharge.charge * 0.18;
        if (
          (platform_type === "CreditCard" || platform_type === "DebitCard") &&
          orignalAmount <= 2000
        ) {
          finalAmount = orignalAmount + platformCharge.charge;
          gst = 0;
        }
        setBreakdown({
          baseRate: orignalAmount,
          total: finalAmount.toFixed(2),
          convenienceFee: platformCharge.charge,
          gst,
        });
      } else if ((platformCharge.charge_type = charge_type.PERCENT)) {
        let gst;

        finalAmount =
          orignalAmount +
          ((orignalAmount * platformCharge.charge) / 100) * 1.18;
        gst = ((orignalAmount * platformCharge.charge) / 100) * 0.18;
        if (
          (platform_type === "CreditCard" || platform_type === "DebitCard") &&
          orignalAmount <= 2000
        ) {
          finalAmount =
            orignalAmount + (orignalAmount * platformCharge.charge) / 100;
          gst = 0;
        }

        setBreakdown({
          baseRate: orignalAmount,
          total: finalAmount.toFixed(2),
          convenienceFee: (orignalAmount * platformCharge.charge) / 100,
          gst,
        });
      }
      setAmount(Math.round(finalAmount * 100) / 100);
      return Math.round(finalAmount * 100) / 100;
    } catch (err: any) {
      return { error: err.message };
    }
  }
  useEffect(() => {
    setAmount(orignalAmount);
    setBreakdown({
      baseRate: orignalAmount,
      total: orignalAmount,
      convenienceFee: 0,
      gst: 0,
    });
  }, [openWindow]);
  useEffect(() => {
    if (upiEnabled && provider === "upi") {
      finalAmountWithMDR("upi", "Others");
    }
    if (showQrMenu === false && provider !== "upi") {
      setAmount(orignalAmount);
      setBreakdown({
        baseRate: orignalAmount,
        total: orignalAmount,
        convenienceFee: 0,
        gst: 0,
      });
    }
  }, [showQrMenu, openWindow, provider]);

  const [cardType, setCardType] = useState("CreditCard");
  const navigate = useNavigate();
  useEffect(() => {
    let apiTimer: any;
    async function fetchApi() {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_PAYMENT_BACKEND}/check-status?transactionId=${collect_request_id}`
        );
        const status = await res.json();

        if (status?.status.toUpperCase() === "SUCCESS") {
          navigate(
            `https://payments.edviron.com/edviron-pg/callback?collect_request_id=${collect_request_id}`
          );
          clearInterval(apiTimer);
          // navigate(
          //   `/payment-success/?collect_id=${collect_request_id}&amount=${amount}`
          // );
        }
        if (
          status?.status.toUpperCase() !== "SUCCESS" &&
          status?.status.toUpperCase() !== "NOT INITIATED"
        ) {
          clearInterval(apiTimer);
          navigate(
            `/payment-failure/?collect_id=${collect_request_id}&amount=${amount}`
          );
        }
      } catch (error) {
        clearInterval(apiTimer);
        // navigate(
        //   `/payment-failure/?collect_id=${collect_request_id}&amount=${amount}`
        // );
      }
    }
    apiTimer = setInterval(() => {
      fetchApi();
    }, 1000);
    return () => clearInterval(apiTimer);
  }, []);

  return (
    <div>
      <div className="w-full  bg-white py-2 pr-4 z-50 fixed max-w-[420px]">
        <div className=" flex items-center justify-between gap-x-2">
          <Link
            to={`${process.env.REACT_APP_PAYMENT_BACKEND}/collect/callback?collect_id=${collect_request_id}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-7 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </Link>
          <img src={schoolSvg} alt="school" />
          <p className=" text-[16px] font-semibold w-full truncate">
            {schoolName && schoolName?.replaceAll("-", " ")}
          </p>
          <h3 className="text-[25px] font-medium text-[#28a840] text-right">
            {amount && <> {`₹${amount?.toLocaleString("hi")}`}</>}
          </h3>
        </div>
        <FeesBreakdown data={breakDown} />
      </div>
      <div
        className={` w-full bg-[#E8EBF6] mt-16 relative text-white duration-500 transition-all ${
          showQrMenu ? " h-[17rem]" : " h-[8rem]"
        } ${openWindow ? "  box-1" : " box"}`}
      >
        {/* <UpiQr setShowUpiWindow={setShowUpiWindow} showQrMenu={showQrMenu} setShowQrMenu={setShowQrMenu}/> */}
        <div className="flex justify-between text-black items-center p-5">
          <div className="flex items-center space-x-2 w-full">
            {!disableUpi && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z"
                />
              </svg>
            )}

            {disableUpi ? (
              <div className="w-full">
                <h5 className=" text-sm text-center font-semibold ">
                  UPI is disabled. Kindly contact your administrator
                </h5>
              </div>
            ) : (
              <div
                onClick={() => {
                  setShowQrMenu(!showQrMenu);
                  setShowUpiWindow(false);
                }}
              >
                <h5 className=" text-xs font-semibold ">
                  {showQrMenu ? "Scan" : "Show"} QR Code
                </h5>
                <p className=" text-xs text-[#717171] font-normal">
                  {showQrMenu ? "Show" : "Scan"} with any UPI App
                </p>
              </div>
            )}
          </div>
        </div>

        {!disableUpi && (
          <div className="w-full flex justify-center items-center">
            {/* <div
              ref={qrRef}
              id="qr"
              className={
                " qrmount transition-opacity ease-in-out duration-500 flex justify-center items-center  " +
                (showQrMenu ? " opacity-100" : " opacity-0")
              }
            ></div> */}
            {isEasebuzzSchool === true && (
              <div
                className={
                  " transition-opacity ease-in-out duration-500 flex justify-center items-center  " +
                  (showQrMenu ? " opacity-100" : " opacity-0")
                }
              >
                <QRCodeSVG value={easebuzzQr?.qr_code} size={157} />;
              </div>
            )}{" "}
            {isEasebuzzSchool === false && (
              <div
                ref={qrRef}
                id="qr"
                className={
                  " qrmount transition-opacity ease-in-out duration-500 flex justify-center items-center  " +
                  (showQrMenu ? " opacity-100" : " opacity-0")
                }
              ></div>
            )}
          </div>
        )}
        {!showQrMenu && (
          <div className="absolute w-full bottom-0">
            {!disableUpi ? (
              <img
                onClick={() => {
                  setShowQrMenu(!showQrMenu);
                  setOpenWindow(!openWindow);
                  setProvider(upiEnabled ? "upi" : "");
                }}
                src={cross}
                className="mx-auto cursor-pointer"
                alt="cross"
              />
            ) : (
              <>
                {openWindow === true && (
                  <img
                    onClick={() => {
                      //setShowQrMenu(!showQrMenu);
                      setOpenWindow(!openWindow);
                      setProvider(upiEnabled ? "upi" : "");
                    }}
                    src={cross}
                    className="mx-auto cursor-pointer"
                    alt="cross"
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>

      <div
        className={
          openWindow === true && showQrMenu === false ? " pb-[6rem]" : " pb-20"
        }
        onClick={() => {
          setShowQrMenu(false);
          //setOpenWindow(!openWindow);
        }}
      >
        <div
          className={` overflow-hidden w-full relative text-white duration-200 transition-all ${
            openWindow ? " h-auto" : " h-0"
          }`}
        >
          <div
            className={
              " transform transition-transform  text-black duration-300 h-full w-full " +
              (openWindow ? "  translate-x-0" : "-translate-x-[100%]")
            }
          >
            {provider === "upi" && (
              <Upi
                paymentId={paymentId}
                payButton={false}
                childComponentFunctionRef={upiRef}
                payRef={payRef}
                collect_request_id={collect_request_id}
                provider={upiProvider}
                prov_id={upiProviderId}
                finalAmountWithMDR={finalAmountWithMDR}
                isBlank={isBlank}
                setUpiProvider={setUpiProvider}
                setUpiProviderId={setUpiProviderId}
                isBuzz={easebuzz}
                isEasebuzzSchool={isEasebuzzSchool}
                easebuzzPaymentId={easebuzzPaymentId}
              />
            )}
            {provider === "cards" && (
              <Card
                childComponentFunctionRef={cardRef}
                paymentId={paymentId}
                payRef={payRef}
                collect_request_id={collect_request_id}
                finalAmountWithMDR={finalAmountWithMDR}
                cardType={cardType}
                isBlank={isBlank}
                isEasebuzzSchool={isEasebuzzSchool}
                easebuzzPaymentId={easebuzzPaymentId}
              />
            )}
            {provider === "acards" && (
              <AmexCreditCard
                childComponentFunctionRef={cardRef}
                paymentId={paymentId}
                payRef={payRef}
                collect_request_id={collect_request_id}
                finalAmountWithMDR={finalAmountWithMDR}
                cardType={cardType}
                isBlank={isBlank}
                isEasebuzzSchool={isEasebuzzSchool}
                easebuzzPaymentId={easebuzzPaymentId}
              />
            )}
            {provider === "netBanking" && (
              <NetBanking
                childComponentFunctionRef={netBankingRef}
                paymentId={paymentId}
                payRef={payRef}
                collect_request_id={collect_request_id}
                finalAmountWithMDR={finalAmountWithMDR}
                isBlank={isBlank}
                isBuzz={easebuzz}
                easebuzzPaymentId={easebuzzPaymentId}
                isEasebuzzSchool={isEasebuzzSchool}
              />
            )}
            {provider === "wallet" && (
              <Wallet
                childComponentFunctionRef={walletRef}
                wallet={wallet}
                paymentId={paymentId}
                payRef={payRef}
                collect_request_id={collect_request_id}
                finalAmountWithMDR={finalAmountWithMDR}
                isBlank={isBlank}
                walletSet={walletSet}
                isEasebuzzSchool={isEasebuzzSchool}
                isBuzz={easebuzz}
                easebuzzPaymentId={easebuzzPaymentId}
              />
            )}
            {provider === "emi-and-paylater" && (
              <PayLater
                childComponentFunctionRef={payLaterRef}
                paymentId={paymentId}
                payRef={payRef}
                collect_request_id={collect_request_id}
                finalAmountWithMDR={finalAmountWithMDR}
                isBlank={isBlank}
                isEasebuzzSchool={isEasebuzzSchool}
                isBuzz={easebuzz}
                easebuzzPaymentId={easebuzzPaymentId}
              />
            )}
            {provider === "cardlessEmi" && (
              <CardLessEMI
                childComponentFunctionRef={cardlessEmiRef}
                providerName={cardLessEmiProvider}
                providerImg={cardLessEmiProviderImg}
                providerText={cardLessEmiProviderText}
                providerHeading={cardLessEmiProviderHeading}
                paymentId={paymentId}
                payRef={payRef}
                collect_request_id={collect_request_id}
                finalAmountWithMDR={finalAmountWithMDR}
                // isBlank={isBlank}
              />
            )}
          </div>
        </div>

        {!disableUpi && upiEnabled && (
          <>
            <p className=" text-sm text-black mt-4 ml-7 font-medium">UPI</p>
            <div className="flex cursor-pointer items-center gap-x-4 overflow-hidden overflow-x-scroll scrollbar-hide py-[10px] px-7 ">
              <div
                onClick={() => {
                  scrollToTop();
                  setOpenWindow(true);
                  setProvider("upi");
                  setPayMethod("upi");
                  setUpiProvider("other");
                  setUpiProviderId("");
                }}
                className="w-[140px] cursor-pointer flex-none flex items-center justify-center  bg-white shadow-black-shadow rounded-lg"
              >
                <div className="flex text-[#717171] items-center">
                  <img
                    className="w-9 h-[37px] object-contain mr-2"
                    src={upiLogo}
                    alt="upi"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {cardEnabled === true && (
          <>
            {" "}
            <p
              className={`text-sm text-black ml-7 ${
                disableUpi ? "mt-6" : " mt-4"
              } font-medium`}
            >
              Cards
            </p>
            <div className="flex cursor-pointer items-center h-[70px] gap-x-4 overflow-hidden overflow-x-scroll scrollbar-hide py-[10px] px-7 ">
              <div
                onClick={() => {
                  // if (isEasebuzzSchool) {
                  //   initiateCard({
                  //     payment_id: easebuzzPaymentId,
                  //     collect_request_id: collect_request_id,
                  //   });
                  // } else {
                  //   scrollToTop();
                  //   setOpenWindow(true);
                  //   setProvider("cards");
                  //   setPayMethod("cards");
                  //   setCardType("CreditCard");
                  //   setAmount(orignalAmount);
                  // }

                  scrollToTop();
                  setOpenWindow(true);
                  setProvider("cards");
                  setPayMethod("cards");
                  setCardType("CreditCard");
                  setAmount(orignalAmount);

                  // scrollToTop();
                  // setOpenWindow(true);
                  // setProvider("cards");
                  // setPayMethod("cards");
                  // setCardType("CreditCard");
                  // setAmount(orignalAmount);
                }}
                className="h-[40px] cursor-pointer flex-none flex justify-center items-center px-3 bg-white shadow-black-shadow rounded-lg"
              >
                <img src={creditCard} className="w-8" alt="google" />
                <h6 className=" text-xs w-full text-[#717171] font-medium">
                  Credit Card
                </h6>
              </div>
              <div
                onClick={() => {
                  // if (isEasebuzzSchool) {
                  //   initiateCard({
                  //     payment_id: easebuzzPaymentId,
                  //     collect_request_id: collect_request_id,
                  //   });
                  // } else {
                  //   scrollToTop();
                  //   setOpenWindow(true);
                  //   setProvider("cards");
                  //   setPayMethod("cards");
                  //   setCardType("DebitCard");
                  //   setAmount(orignalAmount);
                  // }

                  scrollToTop();
                  setOpenWindow(true);
                  setProvider("cards");
                  setPayMethod("cards");
                  setCardType("DebitCard");
                  setAmount(orignalAmount);
                }}
                className="h-[40px] cursor-pointer flex-none flex justify-center items-center px-3 bg-white shadow-black-shadow rounded-lg"
              >
                <img src={creditCard} className="w-8" alt="google" />
                <h6 className=" text-xs text-[#717171] font-medium">
                  Debit Card
                </h6>
              </div>
              <div
                onClick={() => {
                  scrollToTop();
                  setOpenWindow(true);
                  setProvider("acards");
                  setPayMethod("cards");
                  setCardType("AmexCard");
                  setAmount(orignalAmount);
                }}
                className="h-[40px] cursor-pointer flex-none flex justify-center items-center px-3 bg-white shadow-black-shadow rounded-lg"
              >
                <img src={creditCard} className="w-8" alt="google" />
                <h6 className=" text-xs text-[#717171] font-medium">
                  Amex Card
                </h6>
              </div>
            </div>
          </>
        )}
        {netBankingEnabled && (
          <>
            {" "}
            <p className=" text-sm text-black ml-7 font-medium">Net Banking</p>
            <div className="flex cursor-pointer items-center h-[70px] gap-x-4 overflow-hidden overflow-x-scroll scrollbar-hide py-[10px] px-7 ">
              <div
                onClick={() => {
                  scrollToTop();
                  setOpenWindow(true);
                  setProvider("netBanking");
                  setPayMethod("netBanking");
                  setAmount(orignalAmount);
                }}
                className="w-[140px] h-[40px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
              >
                <div className="flex text-[#717171] items-center">
                  <LiaUniversitySolid className="text-2xl" />
                  <h6 className=" text-xs  font-medium ml-2">All Banks</h6>
                </div>
              </div>
            </div>
          </>
        )}
        {walletEnabled && (
          <>
            <p className=" text-sm text-black ml-7 font-medium">Wallets</p>
            <div className="flex cursor-pointer items-center h-[70px] gap-x-4 overflow-hidden overflow-x-scroll scrollbar-hide py-[10px] px-7 ">
              <div
                onClick={() => {
                  scrollToTop();
                  setOpenWindow(true);
                  setProvider("wallet");
                  setPayMethod("wallet");
                  setAmount(orignalAmount);
                  if (easebuzz === "true") {
                    finalAmountWithMDR("wallet", "airtel");
                    walletSet(
                      airtelPay,
                      "Airtel Wallet",
                      "link your Airtel Wallet",
                      "AIRTLM"
                    );
                  } else {
                    finalAmountWithMDR("wallet", "phonepe");
                    walletSet(
                      PhonePe,
                      "PhonePe Wallet",
                      "link your PhonePe Wallet",
                      "phonepe"
                    );
                  }
                }}
                className="w-[140px] h-[40px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
              >
                <div className="flex text-[#717171] items-center">
                  <LiaUniversitySolid className="text-2xl" />
                  <h6 className=" text-xs  font-medium ml-2">Wallets</h6>
                </div>
              </div>
            </div>
          </>
        )}
        {payLaterEnabled && (
          <>
            <p className=" text-sm text-black ml-7 font-medium">Pay Later</p>
            <div className="flex  cursor-pointer items-center h-[70px] gap-x-4 overflow-hidden overflow-x-scroll scrollbar-hide py-[10px] px-7 ">
              <div className="w-[140px] h-[40px] flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg">
                <div
                  onClick={() => {
                    setOpenWindow(true);
                    scrollToTop();
                    setProvider("emi-and-paylater");
                    setPayMethod("payLater");
                    setAmount(orignalAmount);
                  }}
                  className="flex items-center"
                >
                  <img src={pay_later} className="w-6" alt="pay" />
                  <h6 className="text-xs text-[#717171] font-medium ml-2">
                    Pay Later
                  </h6>
                </div>
              </div>
            </div>
          </>
        )}

        {cardLessEnabled ? (
          <>
            <p className=" text-sm text-black mt-2 ml-7 font-medium">
              Cardless EMI
            </p>
            <div className="flex cursor-pointer h-[70px] gap-x-4 overflow-hidden overflow-x-scroll scrollbar-hide py-[10px] px-7 ">
              <div
                onClick={() => {
                  scrollToTop();
                  setOpenWindow(true);
                  setProvider("cardlessEmi");
                  setCardLessEmiProvider("flexmoney");
                  setCardLessEmiProviderText("Create EMI with flexmoney");
                  setCardLessEmiProviderHeading("Flexmoney");
                  setCardLessEmiProviderImg(flexmoney);
                  setPayMethod("cardlessEmi");
                  setAmount(orignalAmount);
                }}
                className="w-[140px] h-[40px] overflow-hidden 4 cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
              >
                <img
                  className=" object-contain w-3/4"
                  src={flexmoney}
                  alt="pay"
                />
              </div>
              <div
                onClick={() => {
                  scrollToTop();
                  setOpenWindow(true);
                  setProvider("cardlessEmi");
                  setCardLessEmiProvider("zestmoney");
                  setCardLessEmiProviderText("Create EMI with zestmoney");
                  setCardLessEmiProviderHeading("Zestmoney");
                  setCardLessEmiProviderImg(zestmoney);
                  setPayMethod("cardlessEmi");
                  setAmount(orignalAmount);
                }}
                className="w-[140px] h-[40px] cursor-pointer flex-none flex items-center justify-center py-3 bg-white shadow-black-shadow rounded-lg"
              >
                <img className=" object-contain" src={zestmoney} alt="pay" />
              </div>
            </div>
          </>
        ) : null}
        <div className="flex bg-[#FBFDFF] justify-center items-center  w-full max-w-[400px] py-3 fixed bottom-0">
          <p className="text-xs text-[#00000080] mr-2">Powered by</p>
          <img src={bottomLogo} className="w-12" alt="bottom banner" />
          {erpLogo && (
            <>
              {" "}
              <p className="text-xs text-[#00000080] mx-2">Co-Powered by</p>
              <img
                src={erpLogo}
                className="w-[4rem] object-cover"
                alt="bottom banner"
              />
            </>
          )}
        </div>
      </div>

      <div
        className={
          "fixed z-50 transform transition-transform duration-300 bottom-0 max-w-[420px] w-full h-[5rem] flex flex-col justify-center bg-white px-8 items-center " +
          (openWindow ? "  translate-y-0" : " translate-y-[100%]")
        }
      >
        <button
          ref={payRef}
          disabled={false}
          onClick={handlePayButton}
          className="w-full text-white font-semibold text-md pay-now-btn bg-[#505E8F] h-[38px] mt-1 rounded-2xl"
          type="submit"
        >
          Pay Now
        </button>
        <div className="w-full mt-1">
          <p className="text-[8px] text-left text-[#717171]">
            *PG charges as applicable (Additional)
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
