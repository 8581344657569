import { useEffect, useState } from "react";

import creditCard from "../../assets/creditCard.svg";

import "./Card.css";
import { useParams } from "react-router-dom";

import amex from "../../assets/cardLogo/amex.png";
import master from "../../assets/cardLogo/master.png";
import rupay from "../../assets/cardLogo/rupay.png";
import visa from "../../assets/cardLogo/visa.png";
import axios from "axios";
import { initiateCard } from "./easebuzz";
function Card({
  finalAmountWithMDR,
  childComponentFunctionRef,
  paymentId,
  payRef,
  collect_request_id,
  cardType,
  isBlank,
  isEasebuzzSchool,
  easebuzzPaymentId,
}) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardCvvNumber, setCardCvvNumber] = useState("");
  const [cardExpiryDate, setCardExpiryDate] = useState("");
  const [card_type, set_card_type] = useState({
    type: "",
    img: "",
  });
  console.log(cardExpiryDate);

  const getCardType = (number) => {
    const cardNumber = number.replace(/\D/g, "");
    const patterns = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      masterCard: /^5[1-5][0-9]{14}$/,
      ruPay: /^6[0-9]{15}$/,
      amex: /^3[47][0-9]{13}$/,
      maestro: /^(?:5[06789][0-9]{0,}|6[0-9]{0,})[0-9]{12,}$/,
    };

    if (patterns.visa.test(cardNumber)) return "Visa";
    if (patterns.masterCard.test(cardNumber)) return "MasterCard";
    if (patterns.ruPay.test(cardNumber)) return "RuPay";
    return "Unknown Card Type";
  };
  const payamount = async () => {
    axios
      .get(
        `${process.env.REACT_APP_PAYMENT_BACKEND}/easebuzz/encrypted-info?card_number=${cardNumber}&card_holder=${cardHolderName}&card_cvv=${cardCvvNumber}&card_exp=${`${cardExpiryDate.slice(0, 3)}20${cardExpiryDate.slice(3)}`}`
      )
      .then((response) => {
        if (response.data) {
          initiateCard({
            payment_id: easebuzzPaymentId,
            card_number: response.data.card_number,
            card_holder_name: response.data.card_holder,
            card_cvv: response.data.card_cvv,
            card_expiry_date: response.data.card_exp,
            type: "CC",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
      });
  };

  useEffect(() => {
    payRef.current.disabled = true;

    if (
      cardNumber !== "" &&
      cardHolderName !== "" &&
      cardCvvNumber !== "" &&
      cardCvvNumber.length === 3
    ) {
      payRef.current.disabled = false;
    }
    if (cardNumber.length > 15) {
      finalAmountWithMDR(cardType, card_type?.type);
    }
  }, [cardNumber, cardHolderName, cardCvvNumber]);

  childComponentFunctionRef.current = payamount;
  return (
    <div>
      <div className="flex items-center mt-8 pl-7 space-x-4">
        <div className="flex justify-between px-3  items-center w-22 h-10 shadow-black-shadow rounded-lg">
          <img src={creditCard} className="w-[39px] h-[29px]" alt="cards" />
          <p className="ml-1 mt-1 font-medium text-[7px] text-[#717171]">
            <span>Credit</span> <br />
            <span>Card</span>
          </p>
        </div>
        <div className="flex flex-col">
          <p className=" text-base text-black font-semibold ">
            Pay with Amex Credit Card
          </p>
          <p className=" text-xs font-normal text-[#717171]">
            Enter Card details
          </p>
        </div>
      </div>

      <div
        className={`credit-card w-full mt-4 h-64 ${isFlipped ? "flipped" : ""}`}
      >
        <div className="front px-4 text-white">
          <div className="h-60 rounded-lg bg-gradient-to-r from-neutral-600 to-zinc-900 p-[30px] text-white shadow-lg">
            <div className="mb-3 flex w-full items-center gap-x-2">
              <img
                src="https://pg.edviron.com/static/media/Chip.fa79a9f20a676e2745b94773b7c0caeb.svg"
                className=""
                alt="cardType"
              />
              <img
                src="https://pg.edviron.com/static/media/Conection%20Icon.f7fcdf1f04fe3f752f9fea9126e4fa13.svg"
                className=""
                alt="cardType"
              />
            </div>

            <div className="mb-4 border-b flex  justify-between items-center">
              <input
                type="text"
                value={cardNumber}
                onChange={(e) => {
                  let input = e.target.value.replace(/\D/g, "");
                  let formatted = "";
                  for (let i = 0; i < input.length; i++) {
                    if (i > 0 && i % 4 === 0) {
                      formatted += " ";
                    }
                    formatted += input[i];
                  }
                  formatted = formatted.slice(0, 19);
                  setCardNumber(formatted);
                  const type = getCardType(input);

                  set_card_type({
                    type: type,
                    img:
                      type === "RuPay"
                        ? rupay
                        : type === "Visa"
                          ? visa
                          : type === "MasterCard"
                            ? master
                            : null,
                  });
                }}
                id="card-number"
                pattern="[0-9]*"
                maxLength="19"
                placeholder="enter card number"
                className="w-full  bg-transparent py-1.5 focus:outline-none"
              />

              <img src={amex} alt="amex" className="h-5" />
            </div>
            <div className="mb-4 flex items-center justify-between">
              <div className="mr-2 w-full">
                <input
                  type="text"
                  id="card-holder"
                  onChange={(e) => {
                    setCardHolderName(e.target.value);
                  }}
                  placeholder="enter card holder name"
                  className="w-full border-b bg-transparent py-1.5 focus:outline-none"
                />
              </div>
              <div className="ml-2 w-1/3">
                <input
                  type="text"
                  id="expiry-date"
                  placeholder="MM/YY"
                  value={cardExpiryDate}
                  onChange={(e) => {
                    let input = e.target.value.replace(/\D/g, "");
                    let formatted = "";
                    for (let i = 0; i < input.length; i++) {
                      if (input.length === 2 && Number(input) > 12) {
                        input = "12";
                      }
                      if (i > 0 && i % 2 === 0) {
                        formatted += "/";
                      }
                      formatted += input[i];
                    }
                    formatted = formatted.slice(0, 6);
                    setCardExpiryDate(formatted);
                  }}
                  className={`w-full border-b bg-transparent py-1.5 focus:outline-none 
                  ${
                    cardExpiryDate.length > 2 &&
                    cardExpiryDate.slice(3) <
                      new Date().getFullYear().toString().slice(2)
                      ? " border-red-500 text-red-500"
                      : " border-white text-white"
                  }
                    
                `}
                  inputmode="numeric"
                  pattern="[0-9]*"
                  maxLength="5"
                />
              </div>
            </div>
            <div className="mb-4 flex items-center justify-center space-x-3">
              <p className="text-[16px] text-[#717171]">CVV</p>
              <input
                value={cardCvvNumber}
                onChange={(e) => {
                  let input = e.target.value.replace(/\D/g, "");
                  setCardCvvNumber(input);
                }}
                type="password"
                id="numberInput"
                maxLength="4"
                placeholder="••••"
                className="w-1/3 bg-transparent py-1.5 focus:outline-none"
                inputmode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Card;
